import React, { Component, useState, useRef, useEffect } from "react"
import './index.css';
import './App.css';
import * as styles from './Home.module.css'
import { navigate } from "gatsby";

import Typed from "typed.js"

import Nav from "../components/Nav"
import Links from "../components/Links"
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { Helmet } from "react-helmet"
import gsap from "gsap"

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


function getEvent(camera, event) {
  console.log(event)
  console.log(event.eventObject.uid)

  console.log(event.eventObject)

  gsap.to(camera.position, {
    duration: 3,
    x: event.eventObject.position.x,
    y: event.eventObject.position.y,
    z: event.eventObject.position.z + 10,
    onUpdate: function () {
      camera.lookAt(event.eventObject.position);
    }
  });

  //event.eventObject;)
  //controls.target = event.eventObject.mesh.position
  //event.eventObject;
}

function CameraController() {
  const { camera, gl } = useThree();
  useEffect(
    () => {

      const controls = new OrbitControls(camera, gl.domElement);

      controls.enableDamping = true;
      controls.enableZoom = false;
      controls.minDistance = 6;

      controls.mouseButtons = {
        RIGHT: THREE.MOUSE.ROTATE,
        MIDDLE: THREE.MOUSE.DOLLY,
        LEFT: THREE.MOUSE.PAN
      }

      controls.touches.ONE = THREE.TOUCH.PAN;
      controls.touches.TWO = THREE.TOUCH.DOLLY_ROTATE;
      controls.enabled = false
      controls.dampingFactor = 0.08;

      //raycaster = new THREE.Raycaster();
      //pointer = new THREE.Vector2();

      //camera.position.x = (camera.position.x)

      initButton();

      return () => {
        controls.dispose();
      };
    },
    [camera, gl]
  );
  return null;
};

function MoveBox(mesh, hovered, viewport) {

  var scalar = (viewport.width / viewport.height) * 3

  if (mesh.current.position.x > viewport.width - scalar) //end of screen
  {

  }

  /** 
    gsap.to(mesh.current.position, {
      duration: 1,
      y: mesh.current.position.y + 2
    }).then(() => {
      gsap.to(mesh.current.position, {
        duration: 2,
        x: -(viewport.width - scalar),
      })

    }).then(() => {
      gsap.to(mesh.current.position, {
        duration: 3,
        y: mesh.current.position.y - 2.5
      })
    }

    );
    */


  if (hovered)
    mesh.current.material.color.setHex(0x000);


  mesh.current.position.x += 0.009
}

function Box(props) {
  // This reference will give us direct access to the mesh
  const { camera, gl, viewport } = useThree();
  const mesh = useRef()
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false)
  const [active, setActive] = useState(false)
  useFrame(() => (MoveBox(mesh, hovered, viewport)))

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={active ? 1.5 : 1}
      onClick={(event) => getEvent(camera, event)}
      onPointerOver={(event) => setHover(true)}
      onPointerOut={(event) => setHover(false)}>
      <boxGeometry args={[3, 3, 3]} />
      <meshStandardMaterial />
    </mesh>
  )
}

function initButton() {
  const btn = document.querySelector(".btn");
  const switchElement = document.querySelector(".switch");

  switchElement.scrollTo(0, switchElement.scrollHeight);

  switchElement.addEventListener("scroll", (e) => {
    const y = e.target.scrollTop * 2;
    if (y > e.target.scrollHeight / 4) {
      switchElement.classList.add("active");
      //document.body.style.background = `rgb(${y}, ${y}, ${y})`;
      //document.body.style.color = `rgb(0,0,0)`;

      btn.style.background = `rgba(${y}, ${y}, ${y}, 1)`;
      switchElement.style.background = `rgba(${y - 8}, ${y - 6}, ${
        y - 3
      }, 1)`;
    } else {
      switchElement.classList.remove("active");
      //document.body.style.background = `rgb(${y + 30}, ${y + 34}, ${y + 38})`;
      btn.style.background = `rgba(${y}, ${y + 8}, ${y + 16}, 1)`;
      //document.body.style.color = `rgb(255,255,255)`;
      switchElement.style.background = `rgba(${y + 20}, ${y + 24}, ${
        y + 28
      }, 1)`;
    }
  });
}

export default class App extends Component {
  constructor() {
    super();

    this.state = {
      bgcolor: "#000",
      hidden: false
    }


    this.links = [
      {
        title: 'LAUNCH APP',
        link: '/app',

        backcolor: "rgb(255, 174, 213, 0.1)",
        txtcolor: "white",
      },

    ]

    this.showDates = [
      "Loading ...",
    ]

  }
  componentDidMount() {
    this.setState({ bgcolor: "#FFF" })
  }

  logoClick() {

    this.setState({ hidden: !this.state.hidden })
    console.log("click")
  }

  render() {
    const style = {
      backgroundColor: this.state.hidden ? "#000" : this.state.bgcolor,
      backgroundBlendMode: this.state.hidden ? "color-dodge" : "normal",
    }

    return (
      <><Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@900&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" sizes="180x180" href="../images/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="../images/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="../images/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="../images/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <meta name="theme-color" content="#ffffff" />
        <title>Coming Soon | BLOXX</title>
      </Helmet>
        <center id="bg" className={styles.bg}>


          <Canvas>
            <CameraController minZoom={10} maxZoom={50} />
            <Box uid={"box1"} position={[-4, 0, 0]} />
            <Box uid={"box2"} position={[1, 0, 0]} />
            <Box uid={"box3"} position={[7, 0, 0]} />

            <ambientLight />
            <pointLight position={[10, 10, 10]} />
          </Canvas>
          <br/><br/>
          <div class="switch">
        <div class="space"></div>
        <div class="btn">scroll me</div>
        <div class="space"></div>
          <Links Links={this.links} />

      </div>

          <div className={styles.footer}>
            BLOXX | ALL RIGHTS RESERVED.
          </div>
        </center>
      </>
    )
  }
}
